<template>
  <div >
    <!--<img class="user-poster" src="https://img.yzcdn.cn/public_files/2017/10/23/8690bb321356070e0b8c4404d087f8fd.png">-->


<van-row class="user-icon">
      <van-col offset="2" span="4">
      <van-image
              round
              width="5rem"
              height="5rem"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            />
      </van-col>
      <van-col offset="2" span="16">
          <van-row>
            <van-col span="4">昵称</van-col>
            <van-col span="12">ID123455678</van-col>
          </van-row>
          <van-row>
            <van-col span="4">14585321205</van-col>
          </van-row>
      </van-col>
    </van-row>


    <van-row class="user-links">
      <van-col span="6">
        <van-icon name="pending-payment" />
        待付款
      </van-col>
      <van-col span="6">
        <van-icon name="records" />
        待接单
      </van-col>
      <van-col span="6">
        <van-icon name="tosend" />
        待发货
      </van-col>
      <van-col span="6">
        <van-icon name="logistics" />
        已发货
      </van-col>
    </van-row>

    <van-cell-group class="user-group">
      <van-cell icon="records" title="全部订单" is-link />
    </van-cell-group>

    <van-cell-group>
      <van-cell icon="points" title="我的积分" is-link />
      <van-cell icon="gold-coin-o" title="我的优惠券" is-link />
      <van-cell icon="gift-o" title="我收到的礼物" is-link />
    </van-cell-group>


  <van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000" >
    <van-tabbar-item name="home" icon="wap-home">首页</van-tabbar-item>
    <van-tabbar-item name="kind" icon="search" url="/kind">分类</van-tabbar-item>
    <van-tabbar-item name="cart" icon="cart" url="/cart">购物车</van-tabbar-item>
    <van-tabbar-item name="user" icon="manager">个人中心</van-tabbar-item>
  </van-tabbar>

  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Tabbar, TabbarItem, Image, Dialog } from 'vant';
import { getCode, getBackendToken, getAccessToken, getRedirectUri } from "@/api/login";

export default {
  data() {
      return {
        active: 'user',
        appId: '0001',
        // 查询参数
        queryParams: {
          redirectUri: 'http://localhost:8080/user',
          scope: 'upapi_base',
          state: 'A123456'
        },
        // 云闪付用户
        userUnion: {
          code: 'I+uhc05IRv+z/g5Ztx0mKQ',
          openId: undefined,
          backendToken: undefined,
          accessToken: undefined
        }

      };
    },
  created() {
      //getRedirectUri();
      //this.queryCode();
      //this.queryBackendToken();
      //this.queryOpenId();
      this.queryUser();


  },
  methods: {
      /** 得到用户 */
      queryUser() {
         // 显示错误信息
         let getParams = getRedirectUri();
         if(getParams.errmsg !== undefined) {
            //Toast.fail(getParams.errmsg);
            Dialog.alert({
              message: getParams.errmsg,
              theme: 'round-button',
            }).then(() => {
              // on close
            });
            return false;
         }

      if(getParams.code === undefined) {
          // 得到code
          getCode(this.queryParams).then(response => {
           //this.loading = false;
           if(response.code == 200){
              document.write(response.data.html);
           } else {
              alert("error");
           }
         });
      }
       this.userUnion.code = getParams.code;
       // 获取backendToken
       console.log("获取backendToken");
       getBackendToken().then(response => {
             if(response.code == 200){
                console.log("backendToken=" + response.data.backendToken);
                console.log("expiresIn=" + response.data.expiresIn);
                this.userUnion.backendToken = response.data.backendToken;

                // 获取accessToken和openId
                console.log("获取accessToken和openId");
                console.log(this.userUnion);
                let query = {'backendToken': this.userUnion.backendToken, 'code': this.userUnion.code};
                getAccessToken(query).then(response => {
                   if(response.code == 200){
                      this.userUnion.accessToken = response.data.accessToken;
                      this.userUnion.openId = response.data.openId;
                   }
                });
             }
             //Dialog.alert({
                  //message: response.msg,
                  //theme: 'round-button',
             //}).then(() => { });
        });
      }
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Image.name]: Image,
    [Dialog.name]: Dialog
  }
};


</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-icon {
    padding-top: 15px;
    padding-bottom: 10px;
    background-color: #7ec77e;
    font-size: 20px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
